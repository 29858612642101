<template>
  <div>
    <BaseModal name="modal_add_integration" :title="$t('seller.note_center.text_2494')">
      <div class="d-flex ml-1">
        <span class="title-item mb-0">01.</span>
        <span class="text-description mb-0 ml-3">
          {{ $t('seller.note_center.text_2495') }}
        </span>
      </div>
      <div class="d-flex justify-content-center">
        <b-row class="mt-4">
          <b-col
            v-for="dataService in notaIntegrationsList"
            :key="dataService.id"
            class="d-flex justify-content-center"
            cols="12"
            sm="12"
            md="6"
            :lg="notaIntegrationsList.length === 4 ? 3 : 4"
          >
            <div
              v-if="dataService.active && dataService.name === 'SmartNotas'"
              v-b-tooltip="{ title: 'SmartNotas', placement: 'top' }"
              class="custom-card-notes"
              @click="openIntegrationSmartNotas"
            >
              <img src="@/assets/img/icons/smartNotas.svg" alt="">
            </div>
            <div
              v-if="dataService.active && dataService.name === 'NotaZZ'"
              v-b-tooltip="{ title: 'NotaZZ', placement: 'top' }"
              class="custom-card-notes"
              @click="openIntegrationNotaZZ"
            >
              <img src="@/assets/img/icons/notaZZ.svg" alt="">
            </div>
            <div
              v-if="dataService.active && dataService.name === 'Enotas'"
              v-b-tooltip="{ title: 'Enotas', placement: 'top' }"
              class="custom-card-notes"
              @click="openIntegrationEnotas"
            >
              <img width="60" src="@/assets/img/icons/enotas.png" alt="">
            </div>
            <div
              v-if="dataService.active && dataService.name === 'SigameDigital'"
              v-b-tooltip="{ title: 'SigameDigital', placement: 'top' }"
              class="custom-card-notes"
              @click="openIntegrationSigameDigital"
            >
              <img src="@/assets/img/icons/sigameDigital.png" alt="" style="max-height: 50px;">
            </div>
          </b-col>
        </b-row>
      </div>
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" @click="cancel">
          {{ $t('seller.note_center.text_2496') }}
        </BaseButton>
      </template>
    </BaseModal>
    <IntegrationSmartNotas/>
    <IntegrationNotaZZ />
    <IntegrationENotas />
    <IntegrationSigameDigital />
  </div>
</template>

<script>
import BaseModal from '../../../BaseModal'
import IntegrationNotaZZ from './IntegrationNotaZZ'
import IntegrationSmartNotas from './IntegrationSmartNotas'
import IntegrationENotas from './IntegrationENotas'
import IntegrationSigameDigital from './IntegrationSigameDigital'
import { mapGetters } from 'vuex'
import hasFlag from '@/utils/validateFlag.js'

import NoteCenter from '@/services/resources/NoteCenter'

const apiNoteCenter = NoteCenter.build()

export default {
  name: 'AddIntegration',
  components: {IntegrationSmartNotas, IntegrationNotaZZ, BaseModal, IntegrationENotas, IntegrationSigameDigital},
  data() {
    return {
      dataServices: []
    }
  },
  computed: {
    ...mapGetters({
      flags: 'getFlags'
    }),
    notaIntegrationsList() {
      let list = this.dataServices
      // Enotas validation
      const enotas = list.find(x => x.name === 'Enotas')
      const indexEnotas = list.indexOf(enotas)
      if(!hasFlag('enotas') && enotas && indexEnotas) {
        list.splice(indexEnotas, 1)
      }
      // Sigma Digital validation
      const sigame_digital = list.find(x => x.name === 'SigameDigital')
      const indexSigma = list.indexOf(sigame_digital)
      if(!hasFlag('sigame_digital') && sigame_digital && indexSigma) {
        list.splice(indexSigma, 1)
      }

      return list
    }
  },
  async created() {
    this.$bvModal.show("modal_add_integration");
    if(!this.flags.length) {
      await this.$store.dispatch('fetchFlags')
    }
    await this.services()
  },
  methods:{
    async services(){
      await apiNoteCenter.get('/service')
        .then(response => {
          this.dataServices = response
        })
    },
    openIntegrationSmartNotas() {
      this.$bvModal.hide("modal_add_integration")
      this.$bvModal.show("modal_integration_smart_notas")
    },
    openIntegrationNotaZZ() {
      this.$bvModal.hide("modal_add_integration")
      this.$bvModal.show("modal_integration_notaZZ")
    },
    openIntegrationEnotas() {
      this.$bvModal.hide("modal_add_integration")
      this.$bvModal.show("modal_integration_enotas")
    },
    openIntegrationSigameDigital() {
      this.$bvModal.hide("modal_add_integration")
      this.$bvModal.show("modal_integration_sigame_digital")
    }
  }
}
</script>

<style scoped>

.title-item{
  color: #2133D2;
}

.text-description{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #81858E;
}

.custom-card-notes {
  cursor: pointer;
  border: solid 1px #F1F2F3;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 18px;
  width: 154px;
  min-height: 103px
}
</style>

<style>
#modal_add_integration___BV_modal_content_ {
  margin-top: 85px;
}
</style>