<template>
  <BaseModal name="notes-filters" size="lg" :title="$t('seller.note_center.text_2515')" @shown="openModal">
    <b-row>
      <b-col cols="12">
        <div class="top-filter">
          <div>
            <p>{{ $t('seller.note_center.text_2516') }}</p>
            <p v-if="contemFilterLocal">
              {{ $t('seller.note_center.text_2517') }}
            </p>
          </div>
          <BaseButton
              variant="link-info"
              class="link-documentacao"
              id="limpar"
              @click="limparLocal"
              :disabled="!contemFilterLocal"
          >{{ $t('seller.note_center.text_2518') }}</BaseButton
          >
        </div>
        <b-form novalidate>
          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.note_center.text_2519')">
                <multiselect
                    v-model="filter.products"
                    id="ajax"
                    label="name"
                    track-by="id"
                    :placeholder="$t('seller.note_center.text_2520')"
                    :selectLabel="$t('seller.note_center.text_2521')"
                    :deselectLabel="$t('seller.note_center.text_2522')"
                    selectedLabel="✔️"
                    open-direction="bottom"
                    :options="products"
                    :multiple="true"
                    :searchable="true"
                    :loading="loading"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :options-limit="10"
                    :show-no-results="true"
                    :hide-selected="true"
                    @search-change="aux_product_debounce"
                >
                  <span slot="noOptions">{{ $t('seller.note_center.text_2523') }}</span>

                  <span slot="noResult"
                  >{{ $t('seller.note_center.text_2524') }}</span
                  >
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('seller.note_center.text_2525')" label-for="status">
                <multiselect
                    v-model="filter.status"
                    :placeholder="$t('seller.note_center.text_2525')"
                    label="name"
                    track-by="id"
                    :options="status_list"
                    :multiple="true"
                    :taggable="false"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :selectLabel="$t('seller.note_center.text_2521')"
                    :deselectLabel="$t('seller.note_center.text_2522')"
                    selectedLabel="✔️"
                ><span slot="noResult"
                >{{ $t('seller.note_center.text_2526') }}.</span
                ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('seller.note_center.text_2527') }}
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit"> {{ $t('seller.note_center.text_2528') }} </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();


export default {
  props: {
    value: {
      type: Number,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      pre_filter: {},
      contemFilterLocal: false,
      search: "",
      loading: false,
      loading_contract: false,
      filter: {
        status: [
          {
            id: "NOT_ISSUED",
            name: this.$t('seller.note_center.text_2595'),
          },
          {
            id: "PROCESSING",
            name: this.$t('seller.note_center.text_2597'),
          },
          {
            id: "ERROR",
            name: this.$t('seller.note_center.text_2576'),
          },
        ],
        products: [],
      },
      products: [],
      contracts: [],
      status_list: [
        {
          id: "NOT_ISSUED",
          name: this.$t('seller.note_center.text_2595'),
        },
        {
          id: "ISSUED",
          name: this.$t('seller.note_center.text_2594'),
        },
        {
          id: "ISSUED_EXTERNALLY",
          name: this.$t('seller.note_center.text_2596'),
        },
        {
          id: "PROCESSING",
          name: this.$t('seller.note_center.text_2597'),
        },
        {
          id: "ERROR",
          name: this.$t('seller.note_center.text_2576'),
        }
      ],
    };
  },

  methods: {
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),

    // contrato
    aux_contract_debounce(query) {
      this.loading_contract = true;
      this.search = query;
      this.debounce_contract();
    },
    onSubmit() {
      window.localStorage.notes_filters_aux = JSON.stringify({
        status: this.filter.status,
        products: this.filter.products,
      });
      let data = {
        status: this.filter.status.map((item) => item.id),
      };
      if (this.filter.products.length) {
        data.products = this.filter.products.map((item) => item.id);
      }
      this.$emit("filter", data);
      this.$bvModal.hide("notes-filters");
    },
    fetchProducts(search = null) {
      let data = {
        list: true,
      };
      if (search) data.search = search;
      this.products = [];
      serviceProduct
          .search(data)
          .then((response) => {
            response.forEach((element) => {
              this.products.push({ id: element.id, name: element.name });
            });
          })
          .catch((err) => {
            console.log(err);
            this.products = [];
          })
          .finally(() => {
            this.loading = false;
          });
    },
    limparLocal() {
      window.localStorage.removeItem("notes_filters_aux");
      window.localStorage.removeItem("sales_filters");
      this.filter = {
        status: [
          {
            id: "NOT_ISSUED",
            name: this.$t('seller.note_center.text_2595'),
          },
          {
            id: "PROCESSING",
            name: this.$t('seller.note_center.text_2597'),
          },
          {
            id: "ERROR",
            name: this.$t('seller.note_center.text_2576'),
          },
        ],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
      };
      this.products = [];
      this.contracts = [];
      this.verificaLocal();
    },
    verificaLocal() {
      if (window.localStorage.notes_filters_aux) {
        this.contemFilterLocal = true;
      } else {
        this.contemFilterLocal = false;
      }
    },
    openModal() {
      this.verificaLocal();
      if (window.localStorage.notes_filters_aux) {
        this.pre_filter = JSON.parse(window.localStorage.notes_filters_aux);
        this.filter = this.pre_filter;
      }
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
</style>
